import React from 'react';

import Slider from '@mshops-components-library/slider';

import withShopDomain from './withShopDomain';
import { useRenderContext } from '../../pages/home/context';

const SliderContainer = (props) => {
  const { device, isEshops, theme, lowEnd } = useRenderContext();

  return (
    <Slider
      layoutTheme={theme}
      deviceType={device}
      lowEnd={lowEnd}
      isEshops={isEshops}
      {...props}
    />
  );
};

export default withShopDomain(SliderContainer);
